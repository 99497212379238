import { css } from 'styled-components';

import { CssSnippet, PagesTemplates } from '@/typings/common';

import { Title, StaticText } from './FormIntro';

export const stylesFormIntroByTheme: Record<PagesTemplates, CssSnippet> = {
  [PagesTemplates.Basic]: css``,
  [PagesTemplates.Singapore]: css``,
  [PagesTemplates.XPP]: css`
    ${Title} {
      font-size: 72px;
      line-height: 74px;
    }
  `,
  [PagesTemplates.Links]: css``,
  [PagesTemplates.Stacks]: css`
    ${Title} {
      text-align: start;
    }

    ${StaticText} {
      text-align: start;
    }
  `,
};
