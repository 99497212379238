import { StoreDispatch } from '@/store/store';
import { getMenuItemListThunk } from '@/store/reducers/tager/menus';
import { getSettingItemListThunk } from '@/store/reducers/tager/settings';
import { MenuAlias } from '@/typings/common';

export function getSharedThunkList(dispatch: StoreDispatch) {
  return [
    dispatch(getMenuItemListThunk(MenuAlias.HeaderLeft)),
    dispatch(getMenuItemListThunk(MenuAlias.HeaderRight)),
    dispatch(getMenuItemListThunk(MenuAlias.Footer)),
    dispatch(getMenuItemListThunk(MenuAlias.Bottom)),
    dispatch(getMenuItemListThunk(MenuAlias.BottomAsia)),
    dispatch(getMenuItemListThunk(MenuAlias.BottomLinks)),
    dispatch(getSettingItemListThunk()),
  ] as const;
}
