import React, { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import scrollManager from '@/services/scroll';

interface Props {
  deps?: React.DependencyList;
}

function useRefreshScroll({ deps }: Props) {
  useEffect(() => {
    function killScrollTriggerInstances() {
      gsap.killTweensOf(window);
      ScrollTrigger.getAll().forEach((instance) => {
        return instance.kill(true);
      });
    }

    killScrollTriggerInstances();

    const scroll = scrollManager.refreshScroll();
    scroll.on('scroll', () => {
      ScrollTrigger.update();
    });
    ScrollTrigger.refresh();
    return () => {
      killScrollTriggerInstances();
    };
  }, deps);

  return null;
}

export default useRefreshScroll;
