import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  name?: string;
  value?: string;
  placeholder?: string;
  onChange?: any;
  error?: string;
  type?: string;
  disabled?: boolean;
};

function TextInput({
  name,
  value,
  type = 'text',
  placeholder,
  onChange,
  disabled,
  error,
}: Props) {
  return (
    <Field>
      <InputWrapper>
        <Input
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          type={type}
          onChange={onChange}
        />
        <Placeholder>{placeholder}</Placeholder>
      </InputWrapper>
      {error ? <Error>{error}</Error> : null}
    </Field>
  );
}

export const Field = styled.div`
  position: relative;
  padding-bottom: 20px;
`;

const Error = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  color: red;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Placeholder = styled.span`
  display: block;
  width: 100%;
  color: #adadaf;

  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  transition: all 250ms ease-in-out;
  text-overflow: ellipsis;

  pointer-events: none;

  position: absolute;
  z-index: 1;
  padding: 0 48px;

  top: 40px;
  font-size: 21px;
  transform: none;

  ${media.mobile(css`
    font-size: 18px;
    padding: 0 24px;
    top: 20px;
  `)}
`;

const Input = styled.input`
  height: 184px;
  border: 0 none;
  background: #f8f8f8;
  width: 100%;
  padding: 0 48px;
  font-size: 31px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: -0.01em;
  transition: all 250ms ease-in-out;
  border-bottom: 2px solid #f2f2f3;
  cursor: pointer;

  ${media.mobile(css`
    height: 90px;
    font-size: 24px;
    padding: 0 24px;
  `)}

  &:hover {
    background: #f2f2f3;
    ~ ${Placeholder} {
      color: ${colors.black};
    }
  }

  &::placeholder {
    color: transparent;
  }

  padding-top: 60px;
  ${media.mobile(css`
    padding-top: 30px;
  `)}
  &:placeholder-shown {
    padding-top: 0;
  }

  &:placeholder-shown ~ ${Placeholder} {
    font-size: 31px;
    top: 50%;
    transform: translateY(-50%);

    ${media.mobile(css`
      font-size: 18px;
    `)}
  }

  &:focus {
    padding-top: 60px;
    border-bottom-color: #4088f8;

    ${media.mobile(css`
      padding-top: 30px;
    `)}

    ~ ${Placeholder} {
      top: 40px;
      font-size: 21px;
      transform: none;

      ${media.mobile(css`
        font-size: 18px;
        top: 20px;
      `)}
    }
  }
`;

export default TextInput;
