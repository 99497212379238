import styled, { css, keyframes } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { CssSnippet } from '@/typings/common';

export const clockwise = keyframes`
  to {
    transform: rotate(360deg) translatez(0);
  }
`;

export const FieldTitle = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 31px;
  line-height: 24px;
  padding-bottom: 30px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);

  span:first-child {
    display: block;
    flex: 1 1 1px;
  }

  &:first-child {
    margin-top: 60px;
  }

  ${media.mobile(css`
    font-size: 22px;
  `)}
`;

export const CheckBoxGroup = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 46px;

  & > div {
    width: 50%;
    margin-bottom: 40px;

    ${media.mobile(css`
      width: 100%;
      margin-bottom: 20px;
    `)}
  }

  ${media.mobile(css`
    padding-left: 0;
  `)}
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 31px;
  line-height: 36px;
  padding-right: 30px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  min-height: 100%;
  ${media.mobile(css`
    order: 1;
    font-size: 22px;
    padding-right: 0;
    padding-left: 30px;
    width: 100%;
  `)}
`;

export const Error = styled.span`
  position: absolute;
  left: 0;
  bottom: -30px;
  color: red;
`;

export const StaticError = styled(Error)`
  position: static;
  margin-left: 10px;
  color: red;
  font-size: 14px;
  font-weight: 400;
`;

export const StyledLabelWrapper = styled.div`
  position: relative;

  ${media.mobile(css`
    width: 100%;

    & > ${StyledLabel} {
      padding-left: 0;
    }
  `)}
`;

export const GroupElements = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 46px;
  margin: 56px 0;

  ${media.mobile(css`
    margin: 20px 0;
    flex-direction: column;
    padding-left: 0;
  `)}
`;

export const GroupElementsInner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.mobile(css`
    margin-top: 20px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `)}
`;

export const FormElement = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  height: 96px;

  ${media.mobile(css`
    width: 100%;
    height: 48px;
    justify-content: space-between;
    margin-right: 0;
    margin-bottom: 20px;
  `)}
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.14);
  margin-top: 40px;
  margin-bottom: 40px;
`;

/** Form Inner styles**/

export const Button = styled.button<{
  loading: boolean;
  disabled?: boolean;
}>`
  background: ${colors.blue};
  display: block;
  text-align: center;
  margin-top: 48px;
  height: 184px;
  width: 100%;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -0.01em;
  transition: 0.3s all ease;
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};

  ${media.mobile(css`
    height: 90px;
    font-size: 24px;
  `)}

  color: ${(props) => (props.loading ? 'transparent' : colors.white)};

  &:hover {
    background: ${(props) =>
      props.loading || props.disabled ? colors.blue : colors.blueHover};
  }

  &:before {
    content: '';
    opacity: ${(props) => (props.loading ? 1 : 0)};
    transition: 0.3s all ease;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    height: 60px;
    width: 60px;
    border-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.75)
      rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.25);
    animation: ${clockwise} 0.5s linear infinite;
    border-width: 6px;
    border-style: solid;
    border-radius: 100%;
  }
`;

export const Component = styled.section<{ stylesByTheme?: CssSnippet }>`
  margin-bottom: 180px;

  ${media.mobile(css`
    margin-top: 0;
    max-width: 100%;
    margin-bottom: 60px;
  `)}

  ${(props) => props.stylesByTheme}
`;

export const FormInputs = styled.div`
  margin-bottom: -20px;
`;

export const Form = styled.form`
  position: relative;
  padding-bottom: 20px;
  margin-top: 74px;

  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

export const FormRow = styled.div`
  margin-top: 28px;

  &:first-child {
    margin-top: 0;
  }

  ${media.mobile(css`
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }
  `)}
`;

export const StaticText = styled.div`
  margin-top: 30px;
`;

export const FormError = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  color: red;
`;

export const CheckboxLabel = styled.label`
  display: block;
  margin-left: 25px;
  padding-left: 37px;
  margin-bottom: 30px;
  cursor: pointer;

  ${media.mobile(css`
    margin-left: 0;
  `)}
  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border: 0 none;
    visibility: hidden;
  }

  span {
    font-weight: 500;
    font-size: 19px;
    line-height: 30px;
    letter-spacing: -0.01em;
    display: block;
    position: relative;

    ${media.mobile(css`
      font-size: 16px;
      line-height: 24px;
    `)}
    a {
      color: ${colors.blue};

      &:hover {
        color: ${colors.blueHover};
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: -37px;
      top: 5px;
      background: ${colors.grayDark};
      width: 19px;
      height: 19px;
      border-radius: 2px;
      display: block;
    }

    &:after {
      content: '';
      position: absolute;
      background: ${colors.blue};
      width: 9px;
      height: 9px;
      border-radius: 2px;
      left: -32px;
      top: 10px;
      display: block;
      opacity: 0;
      transition: 0.3s all ease;
    }
  }

  input:checked + span {
    &:after {
      opacity: 1;
    }
  }
`;
