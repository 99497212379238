import { css } from 'styled-components';

import { CssSnippet, PagesTemplates } from '@/typings/common';
import { colors } from '@/constants/theme';
import { Button } from '@/components/Form.styles';

export const stylesFormByTheme: Record<PagesTemplates, CssSnippet> = {
  [PagesTemplates.Basic]: css``,
  [PagesTemplates.Singapore]: css`
    ${Button} {
      background: ${colors.singaporePrimary};
      input {
        &:focus {
          border-bottom-color: ${colors.singaporePrimary};
        }
      }
    }
  `,
  [PagesTemplates.XPP]: css`
    ${Button} {
      background: ${colors.xppPrimary};
    }

    input {
      &:focus {
        border-bottom-color: ${colors.xppPrimary};
      }
    }
  `,
  [PagesTemplates.Links]: css``,
  [PagesTemplates.Stacks]: css`
    ${Button} {
      background: ${colors.stacksPrimary};
    }

    input {
      &:focus {
        border-bottom-color: ${colors.stacksPrimary};
      }
    }
  `,
};
