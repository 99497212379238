import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

type Props = {
  body?: string;
};

function Static({ body }: Props) {
  return body ? <Component dangerouslySetInnerHTML={{ __html: body }} /> : null;
}

const Component = styled.div`
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: -0.01em;

  p {
    margin-top: 25px;
    &:first-child {
      margin-top: 0;
    }
  }

  a {
    color: ${colors.blue};
    &:hover {
      color: ${colors.blueHover};
    }
  }
`;

export default Static;
