import React from 'react';
import styled, { css } from 'styled-components';

import { Nullish } from '@tager/web-core';

import useCurrentPage from '@/hooks/useCurrentPage';
import { FormPageType } from '@/typings/model';
import Static from '@/components/Static';
import ContentContainer from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { CssSnippet } from '@/typings/common';

interface Props {
  stylesFormIntroByTheme?: CssSnippet;
  title: string;
  introText: Nullish<string>;
}

function FormIntro({ stylesFormIntroByTheme, introText, title }: Props) {
  const page = useCurrentPage<FormPageType>();
  if (!page) return null;

  return (
    <Component stylesFormIntroByTheme={stylesFormIntroByTheme}>
      <ContentContainer width={999}>
        <Title>{title}</Title>
        {introText ? (
          <StaticText>
            <Static body={introText} />
          </StaticText>
        ) : null}
      </ContentContainer>
    </Component>
  );
}

const Component = styled.section<{ stylesFormIntroByTheme?: CssSnippet }>`
  margin-top: 180px;

  ${media.mobile(css`
    margin-top: 120px;
  `)}

  ${(props) => props.stylesFormIntroByTheme}
`;

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: -0.01em;
  position: relative;
  display: inline-block;
  margin: 0;

  font-size: 72px;
  ${media.tablet(css`
    font-size: 50px;
  `)}

  ${media.mobile(css`
    font-size: 38px;
  `)}
`;

export const StaticText = styled.div`
  margin-top: 50px;

  max-width: 100%;
  width: 100%;
  text-align: center;

  ${media.mobile(css`
    margin-top: 30px;
    //max-width: 100%;
  `)}
`;

export default FormIntro;
