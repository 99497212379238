import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FieldArray, useField } from 'formik';

import { useMedia } from '@tager/web-core';

import { colors, fonts } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-down-2.svg';
import scrollManager from '@/services/scroll';

function SelectList({
  options,
  value,
  setOpenDropDownList,
  name = 'license',
}: {
  options: Array<{ label: string; value: string }>;
  value: string;
  setOpenDropDownList: (value: boolean) => void;
  name?: string;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [positionTop, setPosition] = useState<boolean>(false);

  function getCurrentPosition() {
    if (ref.current) {
      const coordinate = ref.current.getBoundingClientRect();
      return coordinate.bottom > window.innerHeight;
    }
    return false;
  }

  useLayoutEffect(() => {
    const position = getCurrentPosition();
    setPosition(position);
  }, []);

  useEffect(() => {
    function handleClickByOutSide(event: MouseEvent) {
      if (ref.current && event.target instanceof Element) {
        if (event.target.contains(ref.current)) {
          setOpenDropDownList(false);
          scrollManager.getLocomotiveScroll()?.start();
        }
      }
    }

    document.addEventListener('click', handleClickByOutSide);
    return () => document.removeEventListener('click', handleClickByOutSide);
  }, []);

  const onMouseOver = () => scrollManager.getLocomotiveScroll()?.stop();
  const onMouseOut = () => scrollManager.getLocomotiveScroll()?.start();

  useEffect(() => {
    if (!ref.current) return;

    ref.current.addEventListener('mouseover', onMouseOver);
    ref.current.addEventListener('mouseout', onMouseOut);

    return () => {
      if (!ref.current) return;
      ref.current.removeEventListener('mouseover', onMouseOver);
      ref.current.removeEventListener('mouseout', onMouseOut);
    };
  }, [ref.current]);

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <OptionContainer ref={ref} positionTop={positionTop}>
          {options &&
            options.length !== 0 &&
            options.map((item, index) => (
              <OptionGroup
                key={`${index}"${item.value}"`}
                isActive={value === item.label}
              >
                <OptionLabel htmlFor={`${index}"${item.value}"`}>
                  {item.label}
                </OptionLabel>
                <Option
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    arrayHelpers.replace(0, item);
                    setOpenDropDownList(false);

                    scrollManager.getLocomotiveScroll()?.start();
                  }}
                  id={`${index}"${item.value}"`}
                  type="checkbox"
                />
              </OptionGroup>
            ))}
        </OptionContainer>
      )}
    />
  );
}

function NativeSelectComponent({
  options,
  name = 'license',
  error,
  defaultValue = 'Select',
}: {
  options: Array<{ label: string; value: string }>;
  name?: string;
  defaultValue?: string;
  error?: string;
}) {
  const [{ value }, meta, { setValue }] = useField({
    name: name ?? '',
  });

  const onChangeSelect = (option: { label: string; value: string }) => {
    setValue(option.value);
  };

  return (
    <React.Fragment>
      <NativeSelect
        name={name}
        value={value}
        onChange={(event) =>
          onChangeSelect({
            label: event.target.options[event.target.selectedIndex].text,
            value: event.target.value,
          })
        }
      >
        {options &&
          options.length !== 0 &&
          options.map((item, index) => (
            <React.Fragment>
              {index === 0 && (
                <NativeOption value="" selected disabled hidden />
              )}

              <NativeOption key={index} value={item.value}>
                {item.label}
              </NativeOption>
            </React.Fragment>
          ))}
      </NativeSelect>

      <StyledIcon isOpen={false} />
      {!value || value?.length < 1 ? (
        <DefaultValue>{defaultValue}</DefaultValue>
      ) : null}
      {error ? <Error>{error}</Error> : null}
    </React.Fragment>
  );
}

function Select({
  name,
  options,
  value,
  error,
  defaultValue = 'Select',
}: {
  options: Array<{ value: string; label: string }>;
  name: string;
  value: string;
  defaultValue?: string;
  error?: string;
}) {
  const isMobile = useMedia(`(max-width: 768px)`);
  const [isOpenDropDownList, setOpenDropDownList] = useState(false);

  return (
    <Container>
      {!isMobile ? (
        <React.Fragment>
          <SelectedOption
            onClick={() => setOpenDropDownList(!isOpenDropDownList)}
          >
            <StyledIcon isOpen={isOpenDropDownList} />
            {!!value ? (
              <SelectedValue>{value}</SelectedValue>
            ) : (
              <DefaultValue>{defaultValue}</DefaultValue>
            )}
          </SelectedOption>
          {error ? <Error>{error}</Error> : null}
          {isOpenDropDownList ? (
            <SelectList
              name={name}
              value={value}
              options={options}
              setOpenDropDownList={setOpenDropDownList}
            />
          ) : null}
        </React.Fragment>
      ) : (
        <NativeSelectComponent
          name={name}
          options={options}
          defaultValue={defaultValue}
          error={error}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 184px;
  background: #f8f8f8;

  ${media.mobile(css`
    height: 90px;
  `)}
`;

const DefaultValue = styled.span`
  position: absolute;
  top: 50%;
  left: 48px;
  transform: translateY(-50%);
  font-weight: 500;
  font-size: 31px;
  line-height: 40px;
  color: #c1c1c1;
  transition: color 250ms ease-in-out;
  pointer-events: none;

  -webkit-letter-spacing: -0.01em;
  -moz-letter-spacing: -0.01em;
  -ms-letter-spacing: -0.01em;
  letter-spacing: -0.01em;

  ${media.mobile(css`
    font-size: 18px;
    left: 24px;
  `)}
`;
const SelectedValue = styled.span`
  font-weight: 500;
  font-size: 31px;
  line-height: 40px;
  color: ${colors.black};
  transition: color 250ms ease-in-out;

  -webkit-letter-spacing: -0.01em;
  -moz-letter-spacing: -0.01em;
  -ms-letter-spacing: -0.01em;
  letter-spacing: -0.01em;
`;

const Error = styled.span`
  position: absolute;
  left: 0;
  bottom: -30px;
  color: red;
`;

const StyledIcon = styled(ArrowIcon)<{ isOpen: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
  transition: transform 0.4s;
  pointer-events: none;

  ${({ isOpen }) =>
    isOpen
      ? css`
          transform: translateY(-50%) rotate(180deg);
        `
      : null}

  ${media.mobile(css`
    right: 30px;
  `)}
`;

const SelectedOption = styled.div<{
  withPrefix?: boolean;
  invalid?: boolean;
}>`
  display: flex;
  align-items: center;
  border: 0 none;
  width: 100%;
  height: 100%;
  padding: 0 48px;
  transition: all 250ms ease-in-out;
  cursor: pointer;

  -webkit-transition: all 250ms ease-in-out;

  &:hover {
    background: #f2f2f3;

    & > span {
      color: ${colors.black};
    }
  }
`;

const OptionGroup = styled.div<{ isActive: boolean }>`
  transition: background 150ms ease-in-out;
  ${({ isActive }) =>
    isActive
      ? css`
          background: #80b0fa;
        `
      : css`
          &:hover {
            background: #f2f2f3;
          }
        `}
`;

const OptionLabel = styled.label`
  display: block;
  font-size: 31px;
  line-height: 24px;
  padding: 35px 0 35px 46px;
  color: ${colors.black};
  font-weight: 500;
  cursor: pointer;
  ${media.mobile(css`
    font-size: 21px;
    padding: 30px 24px;
  `)}
`;

const OptionContainer = styled.div<{ positionTop: boolean }>`
  position: absolute;
  max-width: 100%;
  width: 100%;
  background: #fff;
  z-index: 100;
  box-shadow: 0 8px 26px #f3f3f3;
  max-height: 500px;
  overflow: auto;

  ${({ positionTop }) =>
    positionTop
      ? css`
          top: -156%;
          ${media.mobile(css`
            top: -284%;
          `)}
        `
      : css`
          top: 102%;
        `}
`;

const Option = styled.input`
  display: none;
  font-family: ${fonts.Gilroy};
  width: 100%;
  font-weight: normal;
  font-size: 31px;
  line-height: 24px;
  padding: 35px 0 35px 46px;
  text-transform: uppercase;
  appearance: none;
  border: none;
`;

const NativeSelect = styled.select`
  padding: 0 24px;
  width: 100%;
  height: 100%;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: -0.01em;
  font-family: ${fonts.Gilroy};
  background: transparent;
  color: ${colors.black};

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`;

const NativeOption = styled.option`
  font-size: 21px;
`;

export default Select;
